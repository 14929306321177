import { surveyLocalization } from "survey-core";

export var danishSurveyStrings = {
  pagePrevText: "Tilbage",
  pageNextText: "Videre",
  completeText: "Færdig",
  previewText: "Forpremiere",
  editText: "Redigér",
  startSurveyText: "Start",
  otherItemText: "Valgfrit svar...",
  noneItemText: "Ingen",
  selectAllItemText: "Vælg alle",
  progressText: "Side {0} af {1}",
  panelDynamicProgressText: "Optag {0} af {1}",
  questionsProgressText: "Besvarede {0} / {1} spørgsmål",
  emptySurvey: "Der er ingen synlige spørgsmål.",
  completingSurvey: "Mange tak for din besvarelse!",
  completingSurveyBefore:
    "Vores data viser at du allerede har gennemført dette spørgeskema.",
  loadingSurvey: "Spørgeskemaet hentes fra serveren...",
  optionsCaption: "Vælg...",
  value: "værdi",
  requiredError: "Besvar venligst spørgsmålet.",
  requiredErrorInPanel: "Besvar venligst mindst ét spørgsmål.",
  requiredInAllRowsError: "Besvar venligst spørgsmål i alle rækker.",
  numericError: "Angiv et tal.",
  textMinLength: "Angiv mindst {0} tegn.",
  textMaxLength: "Please enter less than {0} characters.",
  textMinMaxLength: "Angiv mere end {0} og mindre end {1} tegn.",
  minRowCountError: "Udfyld mindst {0} rækker.",
  minSelectError: "Vælg venligst mindst {0} svarmulighed(er).",
  maxSelectError: "Vælg venligst færre {0} svarmuligheder(er).",
  numericMinMax:
    "'{0}' skal være lig med eller større end {1} og lig med eller mindre end {2}",
  numericMin: "'{0}' skal være lig med eller større end {1}",
  numericMax: "'{0}' skal være lig med eller mindre end {1}",
  invalidEmail: "Angiv venligst en gyldig e-mail adresse.",
  invalidExpression: "Udtrykket: {0} skal returnere 'true'.",
  urlRequestError: "Forespørgslen returnerede fejlen '{0}'. {1}",
  urlGetChoicesError:
    "Forespørgslen returnerede ingen data eller 'path' parameteren er forkert",
  exceedMaxSize: "Filstørrelsen må ikke overstige {0}.",
  otherRequiredError: "Angiv en værdi for dit valgfrie svar.",
  uploadingFile:
    "Din fil bliver uploadet. Vent nogle sekunder og prøv eventuelt igen.",
  loadingFile: "Indlæser...",
  chooseFile: "Vælg fil(er)...",
  noFileChosen: "Ingen fil er valgt",
  confirmDelete: "Vil du fjerne den?",
  keyDuplicationError: "Denne værdi skal være unik.",
  addColumn: "Tilføj kolonne",
  addRow: "Tilføj række",
  removeRow: "Fjern",
  addPanel: "Tilføj ny",
  removePanel: "Fjern",
  choices_Item: "valg",
  matrix_column: "Kolonne",
  matrix_row: "Række",
  savingData: "Resultaterne bliver gemt på serveren...",
  savingDataError: "Der opstod en fejl og vi kunne ikke gemme resultatet.",
  savingDataSuccess: "Resultatet blev gemt!",
  saveAgainButton: "Prøv igen",
  timerMin: "min",
  timerSec: "sek",
  timerSpentAll: "Du har brugt {0} på denne side og {1} i alt.",
  timerSpentPage: "Du har brugt {0} på denne side.",
  timerSpentSurvey: "Du har brugt {0} i alt.",
  timerLimitAll:
    "Du har brugt {0} af {1} på denne side og {2} af {3} i alt.",
  timerLimitPage: "Du har brugt {0} af {1} på denne side.",
  timerLimitSurvey: "Du har brugt {0} af {1} i alt.",
  cleanCaption: "Rens",
  clearCaption: "Fjern",
  chooseFileCaption: "Vælg fil",
  removeFileCaption: "Fjern denne fil",
  booleanCheckedLabel: "Ja",
  booleanUncheckedLabel: "Ingen",
  confirmRemoveFile: "Er du sikker på, at du vil fjerne denne fil: {0}?",
  confirmRemoveAllFiles: "Er du sikker på, at du vil fjerne alle filer?",
  questionTitlePatternText: "Spørgsmåls titel",
};

surveyLocalization.locales["da"] = danishSurveyStrings;
surveyLocalization.localeNames["da"] = "dansk";
